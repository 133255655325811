import React, { Component, Fragment } from "react";
import Utils from '../../../utils';
import withStyles from "@material-ui/styles/withStyles";
import { withSnackbar } from "notistack";
import PrintIcon from '@material-ui/icons/Print';
import { STATUS_PICPAY } from '../../../utils/PicPay'
import Alert from '@material-ui/lab/Alert';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Box,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  ListItemSecondaryAction,
  Chip
} from "@material-ui/core";
import printJS from 'print-js'
import CloseIcon from '@material-ui/icons/Close';
import AuthService from "../../../AuthService";
import { STATUS_ASAAS } from "../../../utils/Asaas";


const styles = theme => ({

  Grid: {
    marginTop: "0px",
    marginBottom: "0px",
  },

  title: {
    fontFamily: "Bebas Neue"
  },

  rowWithoutLine: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  listItem: {

    '&.MuiListItem-secondaryAction': {
      paddingRight: '118px'
    }

  }

});

function PaymentSecondaryInfo({ pagamento }) {
  if (pagamento.formaPagamento.tipo === 'PICPAY') {
    return `Status: ${STATUS_PICPAY[pagamento.status]} ${pagamento.authorizationId ? ' - AUT: ' + pagamento.authorizationId : ''}`
  }

  if (pagamento.formaPagamento.tipoPagamento === 'online') {
    return (
      <>
        <Box>{`Status: ${STATUS_ASAAS[pagamento.status]} ${pagamento.authorizationId ? ' - AUT: ' + pagamento.authorizationId : ''}`}</Box>
        {!!pagamento.log && (
          <Typography color="error">{pagamento.log}</Typography>
        )}
      </>
    )
  }

  return null
}

class CadastroCardapio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pedido: props.pedido
    };
    //pedido = props.pedido
  }

  printOrder() {
    printJS({
      printable: 'dialog-order',
      type: 'html',
      header: 'Pedido Qrpedir',
      ignoreElements: ['print-info-item'],
      style: "font-family: Arial, Helvetica, sans-serif",
      maxWidth: 400
    })
  }

  liberarEnvioWebhook(pedido) {
    let service = new AuthService();
    service
      .post("/pedido/liberarEnvioWebhook/" + pedido.id)
      .then(pedidoRet => {
        console.log("Retorno", pedidoRet)
        this.setState({ pedido: pedidoRet })
      })
      .catch(err => {
        console.error('Falha ao alterar pedido para liberar envio por webhook', err)
      });
  }

  render() {
    const { classes } = this.props;
    const { pedido } = this.state;

    return (
      <Fragment>

        <Dialog
          open={this.props.open}
          onClose={this.props.handleOpenInfo}
          ls={12}>

          <DialogTitle >
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography color="primary" className={classes.title} variant="h5" >
                  {Utils.labelTipoIdentificador(pedido.identificador.tipo)} {pedido.identificador.tipo === 'DELIVERY' ? null : pedido.identificador.numero}
                </Typography>
              </Box>
              <Box >
                <IconButton onClick={() => this.printOrder()}><PrintIcon /></IconButton>
                <IconButton onClick={this.props.handleOpenInfo}><CloseIcon /></IconButton>
              </Box>

            </Box>
          </DialogTitle>

          <DialogContent id="dialog-order">

            <Table aria-label="Dados do pedido" size="small">

              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    {pedido.identificadorSecundario ? (
                      <Typography color="textPrimary" display="block" style={{ textTransform: "uppercase" }}>
                        {
                          pedido.identificador.tipo === "DELIVERY" ? (<strong> SEQUENCIAL: {pedido.identificadorSecundario}</strong>) : null
                        }
                        {
                          pedido.identificador.tipo === "SEQUENCIAL" ? (<strong> SENHA: {pedido.identificadorSecundario}</strong>) : null
                        }

                        {
                          (pedido.identificador.tipo !== "SEQUENCIAL" && pedido.identificador.tipo !== "DELIVERY") ?
                            (
                              <strong>{Utils.labelTipoIdentificador(pedido.identificador.empresa.tipoIdentificadorSecundario)}  {pedido.identificadorSecundario}</strong>
                            ) : null
                        }
                      </Typography>
                    ) : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={2}>Pedido # {pedido.id}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {pedido.horarioAgendamento ? (
                  <TableRow>
                    <TableCell component="th" scope="row">Agendado Para</TableCell>
                    <TableCell align="right"><Typography color="secondary" variant="body1">{Utils.formatarDataHora(pedido.horarioAgendamento, pedido.empresa.timezone)}</Typography> </TableCell>
                  </TableRow>
                ) : null}

                <TableRow>
                  <TableCell component="th" scope="row">Cliente</TableCell>
                  <TableCell align="right"><strong >{pedido.nomeCliente}</strong></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">Telefone</TableCell>
                  <TableCell align="right">{pedido.pessoa ? pedido.pessoa.telefone : ''}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">Data</TableCell>
                  <TableCell align="right"><strong > {Utils.formatarDataHora(pedido.dataPedido, pedido.empresa.timezone)}</strong></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">Descrição</TableCell>
                  <TableCell align="right"><strong >{pedido.descricao}</strong></TableCell>
                </TableRow>

                {
                  pedido.tipoDelivery && (
                    <TableRow>
                      <TableCell component="th" scope="row">Escolha de serviço</TableCell>
                      <TableCell align="right">
                        <strong>
                          {{
                            "CONSUMIR_LOCAL": "CONSUMO LOCAL",
                            "ENTREGAR": "ENTREGA",
                            "RETIRAR_LOCAL": "RETIRADA LOCAL"
                          }[pedido.tipoDelivery] || null}
                        </strong>
                      </TableCell>
                    </TableRow>
                  )
                }

                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    dispositivo
                    <Typography variant="caption" display="block" gutterBottom>
                      {pedido.dispositivo}
                    </Typography>
                  </TableCell>
                </TableRow>



                {!pedido.ativo ? (

                  <TableRow>
                    <TableCell component="th" scope="row" colSpan={2}>
                      <Typography color="error">Cancelado: {pedido.motivoCancelamento}</Typography>
                    </TableCell>
                  </TableRow>

                ) : null}


              </TableBody>
            </Table>

            {pedido.tipoPedido === "DELIVERY" ?
              pedido.tipoDelivery === "ENTREGAR" ? (
                <Table aria-label="Dados do pedido" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={2}><strong >Dados para entrega</strong></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">Endereço</TableCell>
                      <TableCell align="right">{`${pedido.endereco}, ${pedido.numero}`}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">Bairro</TableCell>
                      <TableCell align="right">{`${pedido.bairro}, CEP ${pedido.cep}`}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">Complemento</TableCell>
                      <TableCell align="right">{`${pedido.complemento}`}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">Telefone</TableCell>
                      <TableCell align="right">{`${pedido.pessoa?.telefone}`}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">Município</TableCell>
                      <TableCell align="right">{pedido.pessoa?.municipio.nome} - {pedido.pessoa?.estado.sigla}</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>

              ) : null : null
            }

            <Box height={30}></Box>

            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row"><strong>Produto</strong></TableCell>
                    <TableCell component="th" scope="row"><strong>Qtde.</strong></TableCell>
                    <TableCell component="th" scope="row"><strong>Unitário</strong></TableCell>
                    <TableCell component="th" scope="row"><strong>Valor</strong></TableCell>
                    <TableCell component="th" scope="row"><strong>Obs</strong></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>

                  {pedido.itens.sort((a, b) => {
                    const aOrdem = a.ordem;
                    const bOrdem = b.ordem;

                    if (aOrdem > bOrdem) return 1;
                    if (bOrdem > aOrdem) return -1;

                    return 0;
                  }).map(item => (
                    <React.Fragment key={item.id}>
                      <TableRow>

                        <TableCell><span id="print-info-item"> <PrintInfo pedido={pedido} item={item} /></span> {item.produto.nome} </TableCell>
                        <TableCell> {item.quantidade} </TableCell>
                        <TableCell> {Utils.formatNumber(item.valorUnitario, 'decimal')} </TableCell>
                        <TableCell> {Utils.formatNumber(item.valorTotal, 'decimal')} </TableCell>
                        <TableCell> {item.observacao} </TableCell>

                      </TableRow>

                      {
                        item.subItens.map((subItem, index) => (

                          <TableRow key={item.id} style={{ opacity: "0.7" }} >
                            <TableCell > &#8627; {subItem.descricao} </TableCell>
                            <TableCell > {subItem.quantidade} </TableCell>
                            <TableCell > {Utils.formatNumber(subItem.valorUnitario, 'decimal')} </TableCell>
                            <TableCell > {Utils.formatNumber(subItem.valorTotal, 'decimal')} </TableCell>
                            <TableCell> {subItem.observacao} </TableCell>
                          </TableRow>

                        ))
                      }

                    </React.Fragment>

                  ))}
                </TableBody>

              </Table>
            </TableContainer>




            <List dense style={{ marginTop: "30px" }} subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Pagamento
              </ListSubheader>
            }>
              {pedido.pagamentos.map(pagamento => (
                <Fragment key={pagamento.id}>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={pagamento.formaPagamento.descricao + " " + Utils.formatNumber(pagamento.valor)}
                      secondary={<PaymentSecondaryInfo pagamento={pagamento} />}
                    />
                    {pagamento.formaPagamento?.tipoPagamento === "online" && (
                      <ListItemSecondaryAction>
                        <Chip color="primary" label="Pago online" variant="outlined" />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </Fragment>
              )
              )}


            </List>

            {pedido.troco ? (
              <Typography color="textPrimary" variant="subtitle2" display="block" >
                TROCO PARA {Utils.formatNumber(pedido.troco)}
              </Typography>
            ) : null}


            <TableContainer>

              <Table size="small" aria-label="totais do pedido">
                <TableHead>
                  <TableRow>
                    <TableCell> </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      ( + ) TOTAL ITENS
                    </TableCell>
                    <TableCell align="right"> {Utils.formatNumber(Utils.getTotalItensPedido(pedido))}</TableCell>
                  </TableRow>

                  {pedido.tipoDelivery === "ENTREGAR" ? (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        ( + ) TAXA DE ENTREGA
                      </TableCell>
                      <TableCell align="right"> {Utils.formatNumber(pedido.valorTaxaEntrega)}</TableCell>
                    </TableRow>
                  ) : null}


                  <TableRow>
                    <TableCell component="th" scope="row">
                      ( - ) DESCONTO
                    </TableCell>
                    <TableCell align="right"> {Utils.formatNumber(pedido.valorDesconto)} </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <strong>( = ) TOTAL</strong>
                    </TableCell>
                    <TableCell align="right"><strong> {Utils.formatNumber(Utils.getTotalPedido(pedido))} </strong></TableCell>
                  </TableRow>


                </TableBody>
              </Table>

            </TableContainer>

            {pedido.statusHook && (
              <Alert>
                <strong>Webhook</strong><br />
                Status: {pedido.statusHook}<br />
                Tentativas: {pedido.tentativasEnvioHook}<br />
                Envio: {pedido.dataEnvioHook}<br />
                Info: {pedido.logHook}<br />

                {pedido.statusHook !== "PENDENTE" && (
                  <Button onClick={() => this.liberarEnvioWebhook(pedido)}>Liberar novo envio</Button>
                )}

              </Alert>
            )}



          </DialogContent>


        </Dialog>
      </Fragment >
    );
  }
}


function PrintInfo({ pedido, item }) {

  return (
    <Box
      component="span"
      displayPrint="none"
      title={item.statusImpressao === "IMPRESSO" ? ('Impresso ' + Utils.formatarDataHora(item.dataHoraImpressao, pedido.empresa.timezone)) : 'Não impresso'}>
      <PrintIcon fontSize="small"
        color={item.statusImpressao === "IMPRESSO" ? "disabled" : "primary"}
      />
    </Box>
  )

}

export default withSnackbar(withStyles(styles)(CadastroCardapio));