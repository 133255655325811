import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Button, Divider, TextField, Box, Paper, IconButton, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import BarraTitulo from "../Generico/BarraTitulo"
import AuthService from "../../AuthService";
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { withSnackbar } from "notistack";

import Template from '../Generico/Template';
import MenuItem from '@material-ui/core/MenuItem';
import MyDialog from '../Generico/MyDialog'

const styles = theme => ({
    table: {
        minWidth: 600,
    },
});

const TIPO_FORMAS_PAGAMENTO = [
    { label: "DINHEIRO", value: "DINHEIRO" },
    { label: "CARTÃO", value: "CARTAO" },
    { label: 'PICPAY', value: "PICPAY" },
    { label: 'PIX', value: "PIX" },
    { label: 'OUTROS', value: "OUTROS" },
]


const TIPO_FORMAS_PAGAMENTO_ONLINE = [
    { label: "CARTÃO DE CRÉDITO", value: "CREDIT_CARD" },
    { label: 'PIX', value: "PIX" },
]

const TIPO_FORMAS_PIX = [
    { label: 'CPF', value: "CPF" },
    { label: 'CNPJ', value: "CNPJ" },
    { label: 'EMAIL', value: "EMAIL" },
    { label: 'TELEFONE', value: "TELEFONE" },
    { label: 'OUTROS', value: "OUTROS" },
]

class FormaPagamento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formaDePagamentos: [],
            fpInput: {
                descricao: '',
                tipo: '',
                ativo: true,
                ordem: 0,
                tipoChave: '',
                chavePix: '',
                nomeBeneficiario: '',
                cidadeBeneficiario: '',
                tipoPagamento: 'local',
                asaasToken: '',
            },
            open: false,
            rowsPerPage: 10,
            page: 0,
            picPayHelpDialog: false,
            asaasHelpDialog: false,
        };


        this.handleChange = this.handleChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.openPicPayHelp = this.openPicPayHelp.bind(this)
        this.openAsaasHelp = this.openAsaasHelp.bind(this)
    }

    componentDidMount() {
        this.handleListFormaPagamento();
    }

    handleChange = (event) => {
        console.log('CHANGE', event);

        const { fpInput } = this.state;

        let name = event.target.name;
        let value = event.target.value;

        fpInput[name] = value;

        this.setState({ fpInput })

        console.log('CHANGE', fpInput);
    }

    novaFormaDePagamento() {
        let { fpInput } = this.state;
        fpInput = {
            descricao: '',
            tipo: '',
            ativo: true,
            tipoPagamento: 'local',
            asaasToken: '',
        }

        this.setState({ fpInput });
    }


    handleClose() {
        this.setState({ open: false })
    };

    handleClickOpenNew = () => {
        this.setState({ open: true })
        this.novaFormaDePagamento()
    };

    handleClickOpenEdit = (fp) => {
        this.setState({ open: true })

        let fp2 = JSON.parse(JSON.stringify(fp))

        this.setState({ fpInput: fp2 })

        console.log("abrir", this.state.open, fp2)
    };


    handleListFormaPagamento() {

        let service = new AuthService();
        service
            .get("/formaPagamento/list?ativo=true")
            .then(formaDePagamentos => {
                this.setState({ formaDePagamentos })

                console.log(formaDePagamentos)
            })
            .catch(err => {
                console.error(err);
                console.log(`Falha ao buscar as formas de pagamento. Detalhes: ${err.message}`, { variant: 'error' });
            });
    }

    handleDelete(fp, index) {
        let { formaDePagamentos } = this.state;

        let service = new AuthService();
        service
            .delete(`/formaPagamento/${fp.id}`)
            .then(resp => {
                console.log('RESP', resp)


                this.setState({ page: 0 })
                formaDePagamentos.splice(index, 1)

                this.setState({ formaDePagamentos })

                this.props.enqueueSnackbar("Forma de pagamento excluída com sucesso", {
                    variant: "success"
                });
            }).catch(err => {
                this.props.enqueueSnackbar(`Erro ao excluir a forma de pagamento. Detalhes: ${err.message}`, {
                    variant: "error"
                });
            })
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 })
    };

    openPicPayHelp = () => {
        this.setState({ picPayHelpDialog: true })
    }

    openAsaasHelp = () => {
        this.setState({ asaasHelpDialog: true })
    }

    handleSave = () => {
        let { fpInput, formaDePagamentos } = this.state;

        if (fpInput.descricao === "") {
            this.props.enqueueSnackbar("Preencha o campo descrição", {
                variant: "error"
            });
        } else if (fpInput.tipo === "") {
            this.props.enqueueSnackbar("Preencha o campo tipo", {
                variant: "error"
            });
        } else if (fpInput.tipo === "PIX" && fpInput.tipoChave === "") {
            this.props.enqueueSnackbar("Preencha o campo tipo Chave", {
                variant: "error"
            });
        } else if (fpInput.tipo === "PIX" && fpInput.chavePix === "") {
            this.props.enqueueSnackbar("Preencha o campo Chave PIX", {
                variant: "error"
            });
        } else if (fpInput.tipo === "PIX" && fpInput.nomeBeneficiario === "") {
            this.props.enqueueSnackbar("Preencha o campo Nome Beneficiário", {
                variant: "error"
            });
        } else if (fpInput.tipo === "PIX" && fpInput.cidadeBeneficiario === "") {
            this.props.enqueueSnackbar("Preencha o campo Cidade Beneficiário", {
                variant: "error"
            });
        } else {

            let service = new AuthService();

            service
                .post("/formaPagamento", fpInput)
                .then(resp => {

                    let pos = formaDePagamentos.map(function (fp) { return fp.id; }).indexOf(fpInput.id);

                    console.log('SAVE POSIÇÃO', pos)

                    if (pos >= 0) {
                        formaDePagamentos.splice(pos, 1)
                    }

                    formaDePagamentos.push(resp)

                    this.setState({ formaDePagamentos })
                    this.setState({ open: false })

                    this.props.enqueueSnackbar("Salvo com sucesso", {
                        variant: "success"
                    });

                })
                .catch(err => {
                    this.props.enqueueSnackbar('Erro ao cadastrar uma nova forma de pagamento.' + err.message, {
                        variant: "error"
                    });
                });
        }
    }

    render() {
        const service = new AuthService()
        const { classes } = this.props;
        const { formaDePagamentos, page, rowsPerPage, fpInput, open, picPayHelpDialog, asaasHelpDialog } = this.state;
        const tipos = fpInput.tipoPagamento === 'local' ? TIPO_FORMAS_PAGAMENTO : TIPO_FORMAS_PAGAMENTO_ONLINE

        return (
            <React.Fragment>
                <Template documentTitle="Formas de pagamento">
                    <BarraTitulo title="Formas de pagamento" subTitle="Configure as formas de pagamento" />
                    <Paper>
                        <Box p={4}>
                            <Button color="primary"
                                variant="contained"
                                startIcon={<AddIcon />}
                                size="medium"
                                onClick={this.handleClickOpenNew}>
                                Forma pagamento
                            </Button>
                        </Box>

                        <Divider />

                        <Box p={3}>
                            <TableContainer>
                                <Table className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                Descrição
                                            </TableCell>
                                            <TableCell align="left">
                                                Tipo
                                            </TableCell>
                                            <TableCell align="right">
                                                {/* Opções */}
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>


                                    <TableBody>
                                        {formaDePagamentos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((fp, index) => {
                                            return (
                                                <TableRow key={fp.id}>
                                                    <TableCell align="left">
                                                        {fp.descricao}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {!fp.tipoPagamento || fp.tipoPagamento === 'local' ? (
                                                            TIPO_FORMAS_PAGAMENTO.find(e => e.value === fp.tipo).label
                                                        ) : (
                                                            TIPO_FORMAS_PAGAMENTO_ONLINE.find(e => e.value === fp.tipo).label
                                                        )}
                                                    </TableCell>

                                                    <TableCell scope="medium" align="right" xs={2}>

                                                        <Button
                                                            onClick={event => { this.handleClickOpenEdit(fp) }}
                                                            startIcon={<EditIcon />}>
                                                            Editar
                                                        </Button>

                                                        <IconButton
                                                            aria-label="delete"
                                                            variant="text"
                                                            onClick={event => { this.handleDelete(fp, index) }} >
                                                            <DeleteIcon />
                                                        </IconButton>

                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>

                                </Table>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={formaDePagamentos.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage} />
                            </TableContainer>
                        </Box>
                    </Paper>



                    <Dialog
                        fullWidth
                        open={open}
                        onClose={this.handleClose}>

                        <DialogTitle>
                            Forma de pagamento
                        </DialogTitle>

                        <DialogContent>

                            <Grid container spacing={2}>

                                {service.isAdmin() && (
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Tipo Pagamento</FormLabel>
                                            <RadioGroup style={{ flexDirection: 'row' }} name="tipoPagamento" value={fpInput.tipoPagamento} onChange={this.handleChange}>
                                                <FormControlLabel value="local" color="primary" control={<Radio />} label="Pagamento local" />
                                                <FormControlLabel value="online" color="primary" control={<Radio />} label="Pagamento online" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                )}

                                {fpInput.tipoPagamento === 'online' && (
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="asaasToken"
                                            label="Token Asaas"
                                            variant="outlined"
                                            value={fpInput.asaasToken}
                                            onChange={this.handleChange}
                                            InputProps={{
                                                endAdornment: <IconButton onClick={this.openAsaasHelp}><HelpOutlineIcon /></IconButton>,
                                            }} />
                                    </Grid>
                                )}

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        onChange={this.handleChange}
                                        value={fpInput.descricao}
                                        variant="outlined"
                                        label="Descrição"
                                        name="descricao"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        select
                                        name="tipo"
                                        label="Tipo"
                                        onChange={this.handleChange}
                                        value={fpInput.tipo}
                                        variant="outlined"
                                    >
                                        {tipos.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        onChange={this.handleChange}
                                        value={fpInput.ordem}
                                        variant="outlined"
                                        label="Ordem"
                                        name="ordem"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        onChange={this.handleChange}
                                        value={fpInput.codigoExterno}
                                        variant="outlined"
                                        label="Código externo"
                                        name="codigoExterno"
                                        fullWidth
                                    />
                                </Grid>

                                {fpInput.tipo === "PICPAY" ? (
                                    <React.Fragment>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                onChange={this.handleChange}
                                                value={fpInput.picpayToken}
                                                variant="outlined"
                                                label="Token de integração do PicPay"
                                                name="picpayToken"
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <IconButton onClick={this.openPicPayHelp}><HelpOutlineIcon /></IconButton>,
                                                }}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ) : null}

                                {fpInput.tipo === "PIX" && fpInput.tipoPagamento !== 'online' ? (
                                    <React.Fragment>
                                        <Grid item xs={12} md={8}>
                                            <TextField
                                                onChange={this.handleChange}
                                                value={fpInput.chavePix}
                                                variant="outlined"
                                                label="Chave Pix"
                                                name="chavePix"
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                fullWidth
                                                select
                                                name="tipoChave"
                                                label="Tipo Chave"
                                                onChange={this.handleChange}
                                                value={fpInput.tipoChave}
                                                variant="outlined"
                                            >
                                                {TIPO_FORMAS_PIX.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                onChange={this.handleChange}
                                                value={fpInput.nomeBeneficiario}
                                                inputProps={{ maxLength: 25 }}
                                                variant="outlined"
                                                label="Nome do beneficiário"
                                                name="nomeBeneficiario"
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                onChange={this.handleChange}
                                                value={fpInput.cidadeBeneficiario}
                                                variant="outlined"
                                                label="Cidade do beneficiário"
                                                name="cidadeBeneficiario"
                                                fullWidth
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ) : null}

                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                color="primary"
                                variant="text"
                                onClick={this.handleClose}
                                startIcon={<CancelIcon />}>
                                Cancelar
                            </Button>

                            <Button
                                aria-expanded={true}
                                variant="contained"
                                color="secondary"
                                onClick={this.handleSave}
                                startIcon={<SaveIcon />}>
                                Salvar
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <MyDialog
                        isOpen={picPayHelpDialog}
                        title="Como conseguir seu token do PicPay"
                        handleClose={() => this.setState({ picPayHelpDialog: false })}
                        dialogActions={
                            <Button onClick={() => this.setState({ picPayHelpDialog: false })}>Fechar</Button>
                        }>

                        <h3>Passo 1</h3>
                        <p>Criar sua conta de e-comerce no PicPay: <a target="_blank" href="https://ecommerce.picpay.com/">clicando aqui</a></p>

                        <h3>Passo 2</h3>
                        <p>Após sua conta criada, acesse: <strong>Integrações</strong> no menu principal do PicPay</p>

                        <h3>Passo 3</h3>
                        <p>Na aba 'Integrações', busque por <strong>x-picpay-token</strong> e copie</p>

                        <h3>Passo 4</h3>
                        <p>Cole o Token no qrpedir</p>
                    </MyDialog>

                    <MyDialog
                        isOpen={asaasHelpDialog}
                        title="Como conseguir seu token da Asaas"
                        handleClose={() => this.setState({ asaasHelpDialog: false })}
                        dialogActions={
                            <Button onClick={() => this.setState({ asaasHelpDialog: false })}>Fechar</Button>
                        }>

                        <h3>Passo 1</h3>
                        <p>Clique no perfil no canto superior direito e então clique em <strong>Integrações</strong></p>

                        <h3>Passo 2</h3>
                        <p>Clica na aba <strong>Chaves de API</strong></p>

                        <h3>Passo 3</h3>
                        <p>
                            Clica em <strong>Gerar chave de API</strong> e preencha as informações
                            corretamente e então clique em avançar e <strong>Enviar código</strong>
                            que irá enviar para o celular cadastrado o código e então
                            clique em <strong>Gerar chave de API</strong>
                        </p>

                        <h3>Passo 4</h3>
                        <p>
                            Coloque a chave gerada no campo <strong>Token Asaas</strong> e clique em salvar
                            <strong>LEMBRE-SE DE GUARDAR O TOKEN EM UM LOCAL SEGURO POR QUE VOCÊ NÃO TERÁ ACESSO À ELA NO FUTURO</strong>
                        </p>

                    </MyDialog>


                </Template>
            </React.Fragment>
        )
    }
}

export default withSnackbar(withStyles(styles)(FormaPagamento));